.datatable {
  height: 600px;
  padding: 20px;

  .datatableTitle-tiendas{
    background-color: #4678c3;
  }
  .datatableTitle {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .rightActions {
      display: flex;
      align-items: center; // Para alinear verticalmente los elementos

      // Espaciado entre los elementos
      > *:not(:last-child) {
        margin-right: 20px;
      }
    }
    .headTiendas {
      display: flex;
      align-items: center;

      .clienteImg {
        width: 150px; /* Ajusta el ancho de la imagen del cliente según tus necesidades */
        height: 150px; /* Ajusta el alto de la imagen del cliente según tus necesidades */
        border-radius: 50%;
        object-fit: cover;
        margin-right: 30px; /* Añadido margen entre la imagen y el título */
        margin-left: 50px; /* Añadido margen entre la imagen y el título */
      }

      .title-registro-tiendas {
        color: #fff;
        font-size: 35px;
        font-style: normal;
        font-weight: 600;
        line-height: 75px;
      }
    }
    // Estilos para "Registrar Vigilante"
    .title-registro {
      color: #4678c3;
      font-size: 35px;
      font-style: normal;
      font-weight: 600;
      line-height: 75px;
    }
    .searchContainer {
      position: relative;
      display: inline-block;
      .searchInput {
        border-radius: 30px;
        background: #fff;
        padding-right: 40px; // Espacio para el ícono de lupa
        padding: 10px;
      }
      .searchIcon {
        position: absolute;
        right: 10px; // Posiciona el ícono a la derecha
        top: 50%;
        transform: translateY(-50%); // Centra el ícono verticalmente
        pointer-events: none; // Asegura que el ícono no interfiera con los clics en el input
        color: #aaa; // Color del ícono, puedes ajustarlo según tus necesidades
      }
    }
    .link {
      border-radius: 4px;
      border: 1px solid #33a186;
      background: #33a186;
      color: white; // Letras blancas

      &:hover {
        color: white; // Asegura que el color de las letras siga siendo blanco al pasar el cursor sobre el botón
      }
    }
    .link-right {
      margin-right: 20px;
    }
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.active {
      background-color: rgba(0, 128, 0, 0.05);
      color: green;
    }
    &.pending {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }
    &.passive {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .actualizarButton {
      border-radius: 4px;
      border: 1px solid #33a186;
      background: #33a186;
      color: white;
      padding: 5px 15px; /* Añadido para darle un poco de padding al botón */
      cursor: pointer; /* Cambia el cursor a mano al pasar el mouse por encima */
      text-align: center; /* Asegura que el texto esté centrado */
      display: inline-block; /* Permite aplicar propiedades de bloque como padding */

      &:hover {
        background-color: #1cd0a3; /* Un tono más claro al pasar el mouse por encima */
        color: white;
        text-decoration: none; /* Elimina cualquier subrayado al pasar el mouse por encima */
      }
    }

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: #fff;
      border: 1px dotted rgba(0, 0, 139, 0.596);
      cursor: pointer;
      background-color: rgba(122, 164, 192, 1);
    }

    .editIcon {
      cursor: pointer;
      margin: 0 5px;
      color: #33a186; // Puedes ajustar el color según tu preferencia
      &:hover {
        color: #1cd0a3; // Cambia el color al pasar el mouse por encima
      }
    }

    .deleteIcon {
      cursor: pointer;
      margin: 0 5px;
      color: #d03f3f; // Puedes ajustar el color según tu preferencia
      &:hover {
        color: #8e1919; // Cambia el color al pasar el mouse por encima
      }
    }
  }
  .datagrid {
    background-color: white;
  }
  .datagrid .MuiDataGrid-row {
    &:nth-of-type(odd) {
      background-color: white;
    }
    &:nth-of-type(even) {
      background-color: rgba(234, 239, 242, 0.5);
    }
  }
}
