// ... (resto de los estilos)

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;

  .clienteCard {
    position: relative; // Añadido para el posicionamiento del logo
    width: calc(33.33% - 90px); /* Ajustado para tener en cuenta los márgenes */
    margin-bottom: 20px;
    margin: 20px;
    padding-top: 80px; // Aumentado para dar más espacio al logo
    overflow: visible; // Añadido para asegurarnos de que el contenido sobresaliente sea visible
    margin-top: 50px;
    hr {
      border: none; // Elimina el borde por defecto
      height: 1px;
      background-color: #dee2e6; // Establece el color deseado
      margin: 10px 20px; // Añade márgenes laterales
    }
    .clienteLogo {
      position: absolute; // Añadido para el posicionamiento
      top: -40px; // Ajustado para que el logo sobresalga correctamente
      left: 50%; // Centrar horizontalmente
      transform: translateX(-50%); // Centrar horizontalmente
      border-radius: 50%; // Esto hará que la imagen sea un círculo
      object-fit: cover; // Esto asegura que la imagen se ajuste dentro del círculo sin estirarse ni deformarse
      width: 100px;
      height: 100px; 
    }

    .clienteInfo {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;

      .nombreServicio {
        font-weight: bold;
      }
      
      .contactoLogos {
        display: flex;
        gap: 10px;
        .iconContainer {
          width: 40px;
          height: 40px;
          background-color: #d1d4d6;
          border-radius: 50%; // Hace que sea un círculo
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 10px; // Espacio entre los íconos

          .whiteIcon {
            color: white; // Hace que el ícono sea blanco
          }
        }
        .mensajeLogo,
        .telefonoLogo {
          width: 24px;
          height: 24px;
        }
      }
    }

    .acciones {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-left: 10px; // padding al margen izquierdo
      padding-right: 10px; // padding al margen derecho

      .verButton,
      .borrarButton,
      .editarButton {
        padding: 5px 10px;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          filter: brightness(0.9);
        }
      }

      .verButton {
        background-color: #7aa4c0;
        color: white;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 30px;
        &:hover {
          background-color: darken(#0e77bd, 10%);
        }
      }

      .rightActions {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .borrarIcon {
          fill: #d03f3f;
          margin-right: 10px; // Espacio entre los íconos de borrar y editar
        }

        .editarIcon {
          fill: #0e77bd;
        }
      }
    }
  }
}
